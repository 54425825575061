import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import Switch from "react-switch"


import {
	Container,
	Section,
	Row,
	Column,
} from '../../components/layouts'

import {
	Card,
	Title,
	Paragraph,
	List,
	Button,
	Collapsible,
	Link,
} from '../../components/ui'

import {
	clearCoupon
} from '../../factory'

import theme from '../../theme/theme'

const {
	oneTimeDonation,
	whatOffer,
} = theme.sections


// TODO: Specific error for page
class Pricing extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			price: 1,
		}

		this.nextPosition = this.nextPosition.bind(this)
		this.prevPosition = this.prevPosition.bind(this)
		this.handlePlanChange = this.handlePlanChange.bind(this)
	}

	componentWillMount() {
		if(typeof(window) !== 'undefined') {
			window.location = 'https://www.flourishchange.com/'
		}
	}

	componentDidMount() {
		this.props.clearCoupon()
	}

	handlePlanChange() {
		this.setState(prevState => ({ price: prevState.price === 2 ? 1 : 2 }))
	}

	nextPosition() {
		if (this.state.position >= 0) {
			this.setState({
				position: this.state.position < 2 ? this.state.position + 1 : this.state.position,
			})
			try {
				/* eslint-disable-next-line */
	      analytics.track(`Checkout form started`, {
					annualPrice: this.state.price === 2,
					price: whatOffer.content.firstColum[this.state.price].title
				})
			} catch (e) {
				// ignore
			}
		}
	}

	prevPosition() {
		this.setState({
			position: this.state.position > 0 ? this.state.position - 1 : this.state.position,
		})
	}


	render() {
		const {oneClickRelationships, analytics, app, marketing, mobileList}  = whatOffer.content
		const {price} = this.state

		return (
			<Layout>
				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					{/* <Row style={{ marginBottom: 0 }}>
						<Column center style={{ alignItems: 'center', justifyContent: 'center' }}>
							<Title fontSize="36px" color="white">Attending AFP 2019?</Title>
							<Title style={{ textAlign: 'center' }} fontSize="36px" color="white"> Come by booth 202 to get 15% off your first year</Title>
						</Column>
					</Row>*/}
					<Container>
						<Card style={{ maxWidth: '100%', height: 'inherit' }}>
							<Column>
								<Row style={{ marginTop: 15, marginBottom: 0 }}>
									<Column style={{flex: 3, padding: "0px 15px"}}>
										<Title fontSize={'50px'} textAlign={'center'}
											style={{margin: 0, marginBottom: '0.5em', marginLeft: '15px'}}
										>A plan for everyone.</Title>
									</Column>
									<div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'center' }}>
										<Paragraph style={{ fontWeight: 800, lineHeight: '75px', marginRight: 10 }}>Monthly</Paragraph>
										<Switch
											onChange={this.handlePlanChange} checked={this.state.price === 2}
											onColor={theme.colors.sunglow}
											onHandleColor={theme.colors.crusta}
											handleDiameter={30}
											uncheckedIcon={false}
											checkedIcon={false}
											boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
											activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
											height={20}
											width={70}
											className="react-switch"
											id="material-switch"
										/>
										<Paragraph style={{ fontWeight: 800, lineHeight: '75px', marginLeft: 10 }}>Annual</Paragraph>
									</div>
								</Row>
								<Row align="center" justify="space-around" />
								<Column>
									<Collapsible list={mobileList[price]} />
									<Button
										href={'/m/checkout'}
										bgColor={theme.colors.crusta}
										hoverBg={'rgb(247, 119, 47, 0.8)'}
										style={{margin: 'auto', color: 'white', marginTop: 20, width: '80%'}}>
												Checkout Now
									</Button>
									<Button
										href={'https://hello.flourishchange.com/meetings/bradenf/a-la-carte-products'}
										bgColor={'white'}
										hoverTextColor={'white'}
										hoverBg={theme.colors.crusta}
										fontColor={theme.colors.crusta}
										onClick={this.nextPosition}
										style={{margin: 'auto', marginTop: 20, width: '80%', paddingLeft: 0, paddingRight: 0, border: `1px solid ${theme.colors.crusta}`}}>
													Chat with us today!
									</Button>

									<div style={{marginTop: 10}}>
										<Paragraph style={{fontSize: 10, width: '100%', textAlign: 'right'}}>1. Deep analytics are only availible to customers of The Flourish App and/or One-Click Relationships</Paragraph>
										<Paragraph style={{fontSize: 10, width: '100%', textAlign: 'right'}}>* Subject to Google's approval</Paragraph>
									</div>
									<Row style={{ marginTop: 15 }}>
										<Column>
											<Title fontSize={'40px'} textAlign={'center'}>
												Ready for everything?
											</Title>
											<Paragraph fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>
											So excited that you want all our products? Then let's chat!
											</Paragraph>
											<Row marginBottom={'2em'} style={{ marginLeft: 0, marginRight: 0 }}>
												<Column maxWidth={'40%'} style={{marginTop: '2em', borderRight: `5px solid ${theme.colors.sunglow}`}} center>
													<List list={whatOffer.content.products}/>
												</Column>
												<Column maxWidth={'40%'} style={{marginTop: '2em', borderRight: `5px solid ${theme.colors.sunglow}`}} center>
													<Title fontSize={'40px'} textAlign={'center'}>
													$850/mo
													</Title>
													<Title fontSize={'40px'} textAlign={'center'} style={{marginBottom: 0}}>
													$8,500/year
													</Title>
													<Paragraph style={{marginTop: 0, textAlign: 'center'}}>2 months free</Paragraph>
												</Column>
												<Column center style={{marginTop: '2em'}}>
													<Button
														href={'/m/checkout'}
														bgColor={theme.colors.crusta}
														hoverBg={'rgb(247, 119, 47, 0.8)'}
														style={{margin: 'auto', color: 'white', width: '100%'}}>
														Checkout Now
													</Button>
													<Button
														href={'https://hello.flourishchange.com/meetings/bradenf/annual-subscription'}
														bgColor={'white'}
														hoverTextColor={'white'}
														hoverBg={theme.colors.crusta}
														fontColor={theme.colors.crusta}
														onClick={this.nextPosition}
														style={{width: '100%', margin: 'auto', paddingLeft: 0, paddingRight: 0, border: `1px solid ${theme.colors.crusta}`,  marginTop: 20}}>
															Connect with us!
													</Button>

												</Column>
											</Row>
										</Column>
									</Row>
								</Column>
							</Column>
						</Card>
					</Container>
				</Section>
			</Layout>
		)
	}
}

Pricing.propTypes = {
	clearCoupon: PropTypes.func
}

const mapDispatchToProps = (dispatch) => {
	return {
		clearCoupon: () => dispatch(clearCoupon())
	}
}

export default connect(
	null,
	mapDispatchToProps
)(Pricing)
